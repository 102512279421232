import AlegraService from "./alegra.service";
import { ApiService } from "./api.service";

export const CrearFactura = (invoice) => {
  return new Promise(async resolve => {
    return AlegraService.post("invoices", invoice)
      .then((response) => {

        NotificarFactura(response.data.id, response.data.client.email);
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

const NotificarFactura = (invoiceId, mail) => {
  var mails = { emails: [mail] };
  return new Promise(async resolve => {
    return AlegraService.post("invoices/" + invoiceId + '/email', mails)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const PagarFactura = (payment) => {
  return new Promise(async resolve => {
    return AlegraService.post("payments", payment)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const UpdateFacturaPagadaShipex = (payment) => {
  return new Promise(async resolve => {
    return ApiService.put("pagar", payment)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetAccountFromAlegra = () => {
  return new Promise(async resolve => {
    return AlegraService.get("bank-accounts")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetAllFacturas = (filtro) => {
  return new Promise(async resolve => {
    return ApiService.get("facturas/byDate/" + filtro.sucursal + "/" + filtro.fechaInicio + "/" + filtro.fechaFinal)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetAllFacturasNoFilter = () => {
  return new Promise(async resolve => {
    return ApiService.get("facturas/all")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetAllPreFacturas = () => {
  return new Promise(async resolve => {
    return ApiService.get("prefactura/all")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const CrearPreFactura = (invoice) => {
  return new Promise(async resolve => {
    return ApiService.post("prefactura", invoice)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const ActualizarPreFactura = (invoice) => {
  return new Promise(async resolve => {
    return ApiService.put("prefactura", invoice)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const DeletePreFactura = (id) => {
  return new Promise(async resolve => {
    return ApiService.delete("prefactura/" + id)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const CanjearPuntosPagadaShipex = (canje) => {
  return new Promise(async resolve => {
    return ApiService.post("canjear", canje)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}