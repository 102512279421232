<template>
  <v-app dark>
    <v-row>
      <div class="col-12 text-center">
        <div class="stat-card-shipex_primary">
          <div class="stat-card-left_content">
            <p class="stat-card-title m-0">
              Puntos <span class="font-weight-bold">Disponibles</span>
            </p>
            <small class="h2 counter-value fw-bold text-white">{{
              totales.total_puntos_disponibles
            }}</small>
          </div>

          <div class="stat-card-shipex_primary">
            <div class="stat-card-right_content">
              <p class="stat-card-title m-0">
                Puntos <span class="font-weight-bold">canjeados</span>
              </p>
              <small class="h2 counter-value fw-bold text-white">{{
                totales.total_puntos_canjeados
              }}</small>
            </div>
          </div>
        </div>
      </div>
    </v-row>
    <v-row>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
            Terminos y condiciones
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Política de Acumulación y Canje de Puntos de Shipex Panamá
          </v-card-title>

          <v-card-text>
            <h6>1. Propósito del Programa de Puntos</h6>
            <p>
              En Shipex Panamá valoramos la fidelidad de nuestros clientes. Por
              eso, hemos implementado un programa de acumulación y canje de
              puntos para recompensar tu fidelidad. Acumula puntos con cada
              factura y canjéalos por descuentos en tus futuras facturas de tu
              elección.
            </p>
            <h6>2. Acumulación de Puntos</h6>
            <p>
              ¿Cómo acumulas puntos? Por cada dólar (USD) gastado en tu factura,
              acumulas 1 punto. Condiciones de acumulación:
            </p>
            <ul>
              <li>
                Los puntos se suman a tu cuenta personal con cada factura pagada
                en Shipex Panamá. Para ello, debes estar registrado en nuestro
                sistema.
              </li>
              <li>
                Los puntos se suman a tu cuenta personal con cada factura pagada
                en Shipex Panamá. Para ello, debes estar registrado en nuestro
                sistema.
              </li>
              <li>
                Solo se acumulan puntos por el monto total de cada factura,
                excluyendo impuestos, cargos adicionales y promociones
                especiales.
              </li>
            </ul>
            <h6>3. Canje de Puntos</h6>
            <p>
              ¿Cómo puedes canjear tus puntos? Los puntos acumulados pueden ser
              canjeados por descuentos en las facturas futuras de tu elección.
              Tasa de canje: 100 puntos equivalen a 1 dólar (USD) de descuento
              sobre el monto total de la factura. Condiciones de canje:
            </p>
            <ul>
              <li>
                Los puntos solo pueden ser canjeados a la factura de tu elección
                y no están vinculados a alguna factura en especial.
              </li>
              <li>
                El canje se aplica antes de impuestos y gastos adicionales.
              </li>
              <li>Los puntos no son canjeables por dinero en efectivo.</li>
              <li>
                El descuento por canje de puntos no puede combinarse con otras
                promociones o descuentos, salvo que se indique lo contrario en
                condiciones específicas.
              </li>
            </ul>
            <h6>4. Vigencia de los Puntos</h6>
            <ul>
              <li>
                Los puntos tienen una vigencia de 12 meses desde la fecha de la
                última factura en la que se acumularon.
              </li>
              <li>
                Recibirás una notificación previa a la caducidad de tus puntos.
              </li>
              <li>
                Los puntos no canjeados dentro de este plazo serán eliminados de
                tu cuenta.
              </li>
            </ul>
            <h6>5. Consulta de Puntos</h6>
            <p>
              Puedes consultar tu saldo de puntos en cualquier momento a través
              de nuestra plataforma en línea o mediante contacto directo con
              nuestro equipo de atención al cliente.
            </p>
            <h6>6. Modificación del Programa</h6>
            <p>
              Shipex Panamá se reserva el derecho de modificar o cancelar el
              programa de acumulación y canje de puntos en cualquier momento. •
              Se notificará a los clientes sobre cualquier cambio significativo
              mediante correo electrónico, nuestro sitio web o la aplicación
              móvil con anticipación razonable.
            </p>
            <h6>7. Términos Adicionales</h6>
            <ul>
              <li>
                No transferibles: Los puntos acumulados son estrictamente
                personales y no pueden transferirse a otras cuentas.
              </li>
              <li>
                Condiciones de uso: El canje de puntos está sujeto a la
                disponibilidad de facturas en Shipex Panamá y a la normativa
                interna de la empresa.
              </li>
              <li>
                El programa está sujeto a las leyes locales aplicables en
                Panamá.
              </li>
              <li>
                Cualquier disputa relacionada con el programa será resuelta de
                acuerdo con la legislación vigente en Panamá
              </li>
            </ul>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Entendido
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import * as clientService from "../../services/client.module";
import Swal from "sweetalert2";

export default {
  name: "Puntos",
  data() {
    return {
      dialog: false,
      totales: {
        total_libras_facturadas: 0,
        total_puntos_disponibles: 0,
        total_puntos_canjeados: 0,
      },
    };
  },
  methods: {
    getTotalPuntos() {
      clientService
        .GetTotalPuntos()
        .then((total) => {
          this.totales = total;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getTotalPuntos();
  },
};
</script>

<style>
.v-application--wrap {
  min-height: auto !important;
}
.theme--light.v-application {
  background: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.87);
}
@media screen and (max-width: 1024px) {
  .v-dialog {
    margin-top: 100px;
  }
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 80%;
  }
}
</style>