<template>
  <div class="profile-user position-relative d-inline-block mx-auto mb-4">
    <img
      :src="profilePic"
      class="rounded-circle avatar-xl img-thumbnail user-profile-image"
      alt="user-profile-image"
    />
    <!-- <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
      <input
        id="profile-img-file-input"
        type="file"
        name="profilePic"
        accept=".jpg"
        @change="readURL"
        class="profile-img-file-input"
      />
      <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
        <span class="avatar-title rounded-circle bg-light text-body">
          <i class="ri-camera-fill"></i>
        </span>
      </label>
    </div> -->
  </div>
</template>
<script>
import * as clientService from "../../services/client.module.js";

export default {
  name: "Profilepic",
  props: ["profile"],
  data() {
    return {
      profilePic: "",
    };
  },
  methods: {
    readURL(evt) {
      var file = evt.target.files ? evt.target.files[0] : null;

      if (file) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var img = new Image();
          img.onload = () => {};
          img.src = reader.result;
        };
        reader.readAsDataURL(file);

        var formData = new FormData();
        formData.append("profilePic", file);
        this.profilePic = URL.createObjectURL(file);

        clientService
          .UpdateProfilePic(formData)
          .then((res) => {})
          .catch((error) => {});
      }
    },
  },
  mounted() {
    this.profilePic = this.profile;
  },
};
</script>