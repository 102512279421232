import AlegraService from "./alegra.service";
import { ApiService } from "./api.service";

export const GetAlegraClientInfo = (alegraId) => {
  return new Promise(async resolve => {
    AlegraService.get("contacts/" + alegraId, { params: { fields: 'statementLink' } })
      .then((response) => {
        resolve(response.data);
        return response.data;
      })
      .catch(({ response }) => {
        console.log(response);
      });
  });
}

export const GetFacturasAbiertas = async (alegraId) => {
  return new Promise(async resolve => {
    AlegraService.get("invoices", {
      params: {
        start: 0,
        limit: 30,
        order_direction: 'DESC',
        order_field: 'id',
        client_id: alegraId,
        //status: 'open'
      }
    })
      .then((response) => {
        resolve(response.data);
        return response.data;
      })
      .catch(({ response }) => {
        console.log(response);
      });
  });
}

export const GetAlegraIdFromShipexByCode = (code) => {
  return new Promise(async resolve => {
    ApiService.get("/cliente/alegraId/" + code)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}

export const GetClienteInfoByCodeOrCedula = (ref) => {
  return new Promise(async resolve => {
    ApiService.get("getClientInfoByCedOrCode/" + ref)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const UpdateProfilePic = (pic) => {
  return new Promise(async resolve => {
    ApiService.post("profilePic", pic, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const UpdatePlanClient = (planInfo) => {
  return new Promise(async resolve => {
    ApiService.post("plan/update", planInfo)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const Rastreos = () => {
  return new Promise(async resolve => {
    ApiService.get("rastreos/all")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  });
}

export const GuardarRastreo = (tracking) => {
  return new Promise(async resolve => {
    return ApiService.post("rastreo", tracking)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetTotalPuntos = () => {
  return new Promise(async resolve => {
    ApiService.get("total/puntos",)
      .then((response) => {
        resolve(response.data);
        return response.data;
      })
      .catch(({ response }) => {
        console.log(response);
      });
  });
}