<template>
    <div>
        <div class="form-group d-flex align-items-center justify-content-center pt-2">
            <div class="text-center p-2 rounded-4 custom-card-header text-white font-weight-bold">
                Mis Direcciones
            </div>
        </div>
        <div class="row justify-content-center">
            <div v-for="(direccion, index) in filteredDirecciones" :key="index" class="col-md-4 col-sm-12 mb-3">
                <div class="card custom-card shadow border-0 text-uppercase m-auto">
                    <div class="card-header custom-card-header text-white">
                        <h4 class="card-title text-center mb-0 text-white">
                            {{ direccion.tipo }}
                            <i v-if="direccion.tipo.includes('Aérea')" class='ri-flight-takeoff-line'></i>
                            <br />
                            <strong>{{ direccion.titulo }}</strong>
                        </h4>
                    </div>
                    <div class="card-body">
                        <p><strong>Full Name:</strong> {{ `SHIPEX ${cliente.nombre} ${cliente.apellido}` }}</p>
                        <p v-if="direccion.direccion1"><strong>Address Line 1:</strong> {{ direccion.direccion1 }}</p>
                        <p v-if="direccion.direccion2"><strong>Address Line 2:</strong> {{ direccion.direccion2 }}</p>
                        <p v-if="direccion.pais"><strong>Country:</strong> {{ direccion.pais }}</p>
                        <p v-if="direccion.ciudad"><strong>City:</strong> {{ direccion.ciudad }}</p>
                        <p v-if="direccion.estado"><strong>State:</strong> {{ direccion.estado }}</p>
                        <p v-if="direccion.codigoPostal"><strong>Zip Code:</strong> {{ direccion.codigoPostal }}</p>
                        <p v-if="direccion.telefono"><strong>Phone Number:</strong> {{ direccion.telefono }}</p>
                        <button class="btn btn-sm btn-danger w-100" @click="copiarDireccion(direccion)">
                            <i class="ri-file-copy-2-line align-middle"></i>
                            Copiar Dirección
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-12 mb-3">
                <div class="card custom-card shadow border-0 text-uppercase m-auto">
                    <div class="card-header custom-card-header text-white">
                        <h4 class="card-title text-center mb-0 text-white">
                            Carga Marítima
                            <i class='ri-ship-line'></i>
                            <br />
                            <strong>Dirección de Carga Marítima</strong>
                        </h4>
                    </div>
                    <div class="card-body">
                        <p>
                            <strong>
                                Información:
                            </strong>
                            Para utilizar la dirección de carga marítima, debes
                            contactarnos para brindarte una cotización.
                        </p>
                        <button class="btn btn-sm btn-danger w-100" @click="contactenos">
                            <i class="ri-customer-service-line align-middle"></i> Contactar
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Miami",
    props: {
        cliente: {
            type: Object,
            required: true,
        },
        contactoUrl: {
            type: String,
            default: '#'
        }
    },
    data() {
        return {
            direcciones: this.obtenerDirecciones(),
        };
    },
    computed: {
        filteredDirecciones() {
            return this.direcciones.filter(direccion =>
                direccion.titulo &&
                (direccion.direccion1 || direccion.direccion2 || direccion.pais || direccion.ciudad || direccion.estado || direccion.codigoPostal || direccion.telefono)
            );
        }
    },
    methods: {
        obtenerDirecciones() {
            return [
                {
                    titulo: "Sucursal C.C. La Doña",
                    tipo: "Carga Aérea",
                    direccion1: "8432 NW 66th Street",
                    direccion2: `SHIPEX ${this.cliente.tipo_plan}-${this.cliente.casillero}`,
                    pais: "United States",
                    ciudad: "Miami",
                    estado: "FL",
                    codigoPostal: "33166-2629",
                    telefono: "(305) 600-4912"
                },
                {
                    titulo: "Sucursal Colón - 4 Altos",
                    tipo: "Carga Aérea",
                    direccion1: "8432 NW 66th Street",
                    direccion2: `SHIPEX C3${this.cliente.tipo_plan}-${this.cliente.casillero}`,
                    pais: "United States",
                    ciudad: "Miami",
                    estado: "FL",
                    codigoPostal: "33166-2629",
                    telefono: "(305) 600-4912"
                },
                {
                    titulo: "Sucursal Arraijan",
                    tipo: "Carga Aérea",
                    direccion1: "8432 NW 66th Street",
                    direccion2: `SHIPEX A${this.cliente.tipo_plan}-${this.cliente.casillero}`,
                    pais: "United States",
                    ciudad: "Miami",
                    estado: "FL",
                    codigoPostal: "33166-2629",
                    telefono: "(305) 600-4912"
                },
            ];
        },
        copiarDireccion(direccion) {
            const texto = `${direccion.direccion1 || ''}, ${direccion.direccion2 || ''}, ${direccion.ciudad || ''}, ${direccion.estado || ''}, ${direccion.codigoPostal || ''}`.trim();
            navigator.clipboard.writeText(texto)
                .then(() => {
                    alert('Dirección copiada al portapapeles!');
                })
                .catch(err => {
                    console.error('Error al copiar la dirección: ', err);
                });
        }
    }
};
</script>

<style scoped>
.custom-card {
    width: 20rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.custom-card-header {
    background-color: #4E6B79;
    color: white;
    text-align: center;
    padding: 1rem;
    font-weight: bold;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card-body p {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}
</style>