<template>
  <v-app>
    <v-card class="elevation-2">
      <v-card-title class="bg-dark text-white" primary-title>
        <v-col class="text-center p-0 font-weight-bold">
          Cierre de Factura
        </v-col>
      </v-card-title>
      <div class="my-4 px-4">
        <!-- <div class="row">
          <div class="col-md">
            <div class="form-group">
            <label class="h5">Cargue su archivo excel de facturas</label>
            <input
              class="form-control"
              type="file"
              @change="onLoadFile"
              accept=".xlsx,.xls"
            />
          </div>
          </div>
          
        </div> -->
        <form
          id="form_cierrefactura"
          method="post"
          class="needs-validation"
          autocomplete="on"
        >
          <div class="row justify-content-center">
            <div class="form-group col-md-6 col-sm-12">
              <label for="selectSucursal" class="font-weight-bold"
                >Imprimir por sucursal</label
              >
              <select
                id="selectSucursal"
                class="form-control"
                name=""
                v-model="filtro.sucursal"
                @change="getFacturas()"
              >
                <option value="0" selected>Seleccione una sucursal</option>
                <option value="1">Sucursal C.C. La Doña</option>
                <option value="11">Sucursal Colón</option>
                <option value="12">Sucursal Arraijan</option>
              </select>
            </div>
            <div class="col-6">
              <button
                class="btn btn-success btn-block mt-5"
                @click.prevent="generateExcel"
              >
                Generar Excel
                <v-icon color="#ffff">mdi-download</v-icon>
              </button>
            </div>
          </div>

          <div class="row justify-content-center">
            <!-- <div class="col-sm-auto col-lg-auto">
                <div class="counter counter-secondary">
                <strong data-to="15">{{ packagesCount }}</strong>
                <label>Cantidad de Paquetes nuevos a bodega</label>
                </div>
            </div>
            <div class="col-sm-auto col-lg-auto">
                <div class="counter counter-secondary">
                <strong data-to="15">{{ invoicesCount }}</strong>
                <label>Facturas a crear</label>
                </div>
            </div>
            <div class="col-sm-auto col-lg-auto">
                <div class="counter counter-secondary">
                <strong data-to="15">{{ invoicesCreatedCount }}</strong>
                <label>Facturas creadas en alegra</label>
                </div>
            </div>
            <div class="col-sm-auto col-lg-auto">
                <div class="counter counter-secondary">
                <strong data-to="15">{{ invoicesFailedCount }}</strong>
                <label>Facturas fallidas</label>
                </div>
            </div> -->

            <div class="form-group col-md-6">
              <label
                for="fechaFinal"
                class="col-form-label text-md-right font-weight-bold"
                >Fecha de Inicio</label
              >
              <input
                id="fechaInicio"
                type="date"
                class="form-control"
                v-model="filtro.fechaInicio"
                name="fechaInicio"
                required
                autofocus
                @change="updateDateFilter"
              />
              <div class="invalid-feedback">Fecha de inicio requerida.</div>
            </div>
            <div class="form-group col-md-6">
              <label
                for="fechaInicio"
                class="col-form-label text-md-right font-weight-bold"
                >Fecha Final</label
              >
              <input
                id="fechaFinal"
                type="date"
                class="form-control"
                v-model="filtro.fechaFinal"
                name="fechaFinal"
                required
                autofocus
                @change="updateDateFilter"
              />
              <div class="invalid-feedback">Fecha de Final requerida.</div>
            </div>
          </div>
        </form>
        <!-- <div class="row">
          <div class="col-md">
            <VueLadda
              button-class="btn btn-success btn-block"
              data-style="zoom-in"
              :loading="isLoading"
              @click="saveFacturasAlegra"
            >
              Procesar Facturas
            </VueLadda>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md">
            <v-data-table
              :headers="headers"
              :items="facturas"
              :single-expand="true"
              :expanded.sync="expanded"
              show-expand
              :search="search"
              class="border elevation-1"
              item-key="numero_factura"
              @click:row="rowClick"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <table class="table table-bordered">
                    <tr
                      v-for="prod in JSON.parse(item.items)"
                      v-bind:key="prod.reference"
                    >
                      <td>Producto: {{ prod.description }}</td>
                      <td>Cantidad: {{ prod.quantity }}</td>
                      <td>Precio: {{ prod.price }}</td>
                    </tr>
                  </table>
                </td>
              </template>
              <!-- <template v-slot:[`item.uploaded`]="{ item }">
              <v-icon
                v-if="item.uploaded == 0"
                medium
                class="mr-2 text-danger"
                v-b-tooltip.hover
                title="No cargado en alegra"
              >
                mdi-block-helper
              </v-icon>
              <v-icon
                v-if="item.uploaded == 1"
                medium
                class="mr-2 text-success"
                v-b-tooltip.hover
                title="Cargado en alegra"
              >
                mdi-check
              </v-icon>
            </template> -->
            </v-data-table>
          </div>
        </div>
      </div>
    </v-card>
  </v-app>
</template>
<script>
import moment from "moment";
import * as facturaService from "../../services/factura.module.js";
import Swal from "sweetalert2";
var XLSX = require("xlsx");
export default {
  name: "Cierrefactura",
  components: {
    // VueLadda,
  },
  data() {
    return {
      headers: [
        { text: "Fecha", value: "fecha", align: "center" },
        { text: "Factura", value: "numero_factura", align: "center" },
        { text: "Cliente", value: "cliente", align: "center" },
        { text: "Total", value: "total", align: "center" },
        { text: "Detalle", value: "data-table-expand", align: "center" },
        { text: "Cargado", value: "uploaded", align: "center" },
      ],
      facturas: [],
      expanded: [],
      search: "",
      filtro: {
        fechaInicio: moment(new Date()).format("YYYY-MM-DD"),
        fechaFinal: moment(new Date()).format("YYYY-MM-DD"),
        sucursal: 0,
      },
    };
  },
  methods: {
    rowClick(value) {
      this.expanded = [];
      this.expanded.push(value);
    },
    updateDateFilter() {
      this.getFacturas();
      Vue.$toast.open({
        message: "Dale con calma io, tamos filtrando...!",
        type: "info",
        duration: 5000,
      });
    },
    getFacturas() {
      facturaService
        .GetAllFacturas(this.filtro)
        .then((facturas) => {
          this.facturas = facturas;
          if (this.facturas.length > 0) {
            Vue.$toast.open({
              message: "Alli tienes tus facturas filtradas!",
              type: "success",
              duration: 5000,
            });
          } else {
            Vue.$toast.open({
              message: "Xa,en estos rangos de fecha no encontramos nada :( !",
              type: "warning",
              duration: 5000,
            });
          }
        })
        .catch((error) => console.log(error));
    },
    generateExcel() {
      var filterInvoices = this.facturas.filter((item) => {
        return item.sucursal == this.filtro.sucursal;
      });

      const data = XLSX.utils.json_to_sheet(filterInvoices);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Excel sucursal.xlsx");

      Swal.fire({
        icon: "success",
        title: "Excel sucursal.xlsx Generado",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        position: "bottom-end",
      });
    },
  },
  mounted() {
    this.getFacturas();
    Vue.$toast.open({
      message: "Aguanta, estamos obteniendo las facturas de hoy!",
      type: "info",
      duration: 7000,
    });
  },
};
</script>